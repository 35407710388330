 @font-face {
   font-family: Visitor; src: url('./flappybird/visitor.ttf');
 }

.game-container {
  width: 288px;
  height: 512px;
  display: block;
  position: relative;
  border: 1px solid #ccc;
  overflow: hidden;
}
.gameover-modal {
  position: absolute;
  background: url(./flappybird/text_game_over.png) 0 0/contain no-repeat;
  width: 100%;
  height: 33%;
  top: 33%;
}

.score-panel {
  font-family: Visitor;
  font-weight: bold;
  color: white;
  position: absolute;
  background: url(./flappybird/score_panel_2.png) 0 0/contain no-repeat;
  width: 62px;
  height: 150px;
  left: 112px;
  top: 58px;
}

.score {
  font-size: 25px;
  position: absolute;
  top: 12px;
  right: 15px;
}

.score-label {
  position: absolute;
  font-size: 25px;
  font-family: Visitor;
  color: white;
  top: 62px;
  left: 116px;
}

.best-score {
  font-size: 25px;
  position: absolute;
  top: 43px;
  right: 15px;
}

.restart-button {
  background: url(./flappybird/button_restart.png) 0 0/contain no-repeat;
  width: 80px;
  height: 28px;
}

.gameover-modal {
  position: absolute;
  background: url(./flappybird/text_game_over.png) 0 0/contain no-repeat;
  width: 100%;
  height: 33%;
  top: 33%;
}

.bird {
  background: red;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
